import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import TvOutlinedIcon from '@material-ui/icons/TvOutlined';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import KingBedOutlinedIcon from '@material-ui/icons/KingBedOutlined';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';
import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
// import useTrendingCategories from 'src/../hooks/useTrendingCategories';
import useGetTrendingCategories from 'src/hooks/api/categories/useGetTrendingCategories';
import { ICategory } from 'src/interface';

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;
`;
const CategoryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 90px;
  padding: 5px 0;
  background: rgba(78, 78, 78, 0.8);
  border-radius: 3px;
  color: white;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    background: rgba(78, 78, 78, 1);
  }
`;
const CategoryTitle = styled.h5`
  margin: 0;
  padding: 0;
  color: white;
  font-size: 13px;
  line-height: 1;
  text-align: center;
`;
/* const CategoryIcon = styled('img')`
  //color: #3fb865 !important;
  //font-size: 32px !important;
  margin-bottom: 8px;
`; */
const Cat1Icon = styled(TvOutlinedIcon)`
  color: #3fb865 !important;
  font-size: 32px !important;
  margin-bottom: 8px;
`;
const Cat2Icon = styled(LibraryBooksOutlinedIcon)`
  color: #3fb865 !important;
  font-size: 32px !important;
  margin-bottom: 8px;
`;

const Cat3Icon = styled(FaceOutlinedIcon)`
  color: #3fb865 !important;
  font-size: 32px !important;
  margin-bottom: 8px;
`;

const Cat4Icon = styled(KingBedOutlinedIcon)`
  color: #3fb865 !important;
  font-size: 32px !important;
  margin-bottom: 8px;
`;

const Cat5Icon = styled(ApartmentOutlinedIcon)`
  color: #3fb865 !important;
  font-size: 32px !important;
  margin-bottom: 8px;
`;

const Cat6Icon = styled(DriveEtaOutlinedIcon)`
  color: #3fb865 !important;
  font-size: 32px !important;
  margin-bottom: 8px;
`;

const TrendingCategories = ({ categories: Categories }: { categories: ICategory[] }) => {
  // const [Categories] = useTrendingCategories();
  // const { Categories } = useGetTrendingCategories();

  const renderCategoryIcon = (id: number): React.ReactElement | null => {
    if (id === 37) {
      return <Cat2Icon />;
    }
    if (id === 43) {
      return <Cat4Icon />;
    }
    if (id === 12) {
      return <Cat6Icon />;
    }
    if (id === 21) {
      return <Cat3Icon />;
    }
    if (id === 1) {
      return <Cat1Icon />;
    }
    if (id === 29) {
      return <Cat5Icon />;
    }
    return null;
  };
  return (
    <CategoriesContainer>
      {Categories?.map((category) => {
        return (
          <Link
            key={category.id}
            href="/[parentCategory]"
            as={`/${category.slug}`}
            prefetch={false}
          >
            <CategoryItem>
              {renderCategoryIcon(category.id)}
              <CategoryTitle>{category.name}</CategoryTitle>
            </CategoryItem>
          </Link>
        );
      })}
    </CategoriesContainer>
  );
};

export default TrendingCategories;
