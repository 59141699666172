import React, { useEffect } from 'react';
// import config from 'src/config';

// const ID = config.googleAdSenseClient;
// const SLOT = config.googleAdSenseSlot;

const AdBanner = ({ slotId }: any) => {
  useEffect(() => {
    try {
      // @ts-ignore
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <ins
      className="adsbygoogle"
      style={{
        display: 'block',
      }}
      data-ad-client="ca-pub-3539874847561243"
      data-ad-slot={slotId}
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
};

export default AdBanner;
