import React from 'react';
import styled from 'styled-components';
import MuiContainer from '@material-ui/core/Container';
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINTS } from 'src/utils/constants';
import TextSlider from './TextSlider';
import Search from './Search';

type TStyledProps = {
  isDesktop: boolean;
};
const Section = styled.section<TStyledProps>`
  background: transparent url('/assets/background/bg-1.webp') center center/cover no-repeat scroll;
  padding: ${({ isDesktop }) => (isDesktop && '40px 0') || '125px 0'};
  width: 100%;
  display: flex !important;
`;
const Container = styled(MuiContainer)<TStyledProps>`
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;
const ExploreSection = styled.div`
  padding: 0 15px;
  max-width: 820px;
`;

type Props = {
  children: React.ReactNode;
};
const HeroSlider = ({ children }: Props) => {
  const isDesktop = useMediaQuery(BREAKPOINTS.DESKTOP);
  return (
    <Section isDesktop={isDesktop}>
      <Container maxWidth="lg" isDesktop={isDesktop}>
        <ExploreSection>
          <TextSlider />
          {children}
        </ExploreSection>
        <Search />
      </Container>
    </Section>
  );
};
export default HeroSlider;
