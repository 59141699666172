import React from 'react';
import { NextSeo } from 'next-seo';
import client from 'src/utils/axios';
import { GetStaticProps } from 'next';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINTS } from 'src/utils/constants';
import config from 'src/config';
import { ICategory, IListings } from 'src/interface';
import HeroSection from '../components/LandingPage/Sections/HeroSection';
import TrendingCategories from '../components/LandingPage/Sections/HeroSection/TrendingCategories';
import {
  FeatureListings,
  NewListings,
  WantedListings,
  HowItWorks,
} from '../components/LandingPage/Sections';
import AdBanner from '../components/Adsense';
import BlogItems from '../components/LandingPage/Sections/LatestBlogs';

export const AdHorizontal = styled.div`
  margin: 10px auto;
  max-width: 970px;
  max-height: 250px;
  overflow: hidden;
`;

export interface ILandingPageProps {
  canonicalUrl: string;
  trendingCategories: ICategory[];
  featuredListings: IListings;
  trendingListings: IListings;
  wantedListings: IListings;
}
const LandingPage = ({
  canonicalUrl,
  trendingCategories,
  featuredListings,
  trendingListings,
  wantedListings,
}: ILandingPageProps) => {
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  return (
    <>
      <NextSeo
        canonical={canonicalUrl}
        title="Rentable Homepage - Turn your liabilities into paying Assets"
        description="Rentable has rental ads available in Pakistan of goods from cars, furniture, electronics to every other rentable service listings. Rent Now!"
        openGraph={{
          url: canonicalUrl,
          title: 'Rentable Homepage - Turn your liabilities into paying Assets',
          description:
            'Rentable has rental ads available in Pakistan of goods from cars, furniture, electronics to every other rentable service listings. Rent Now!',
          images: [
            {
              url: 'https://rentable.pk/assets/logos/green-text-logo.png',
              alt: 'Rentable Pakistan',
            },
          ],
        }}
      />

      <HeroSection>
        <TrendingCategories categories={trendingCategories} />
      </HeroSection>
      {!isMobile && (
        <AdHorizontal>
          <AdBanner slotId="4100436110" />
        </AdHorizontal>
      )}
      <HowItWorks />
      <FeatureListings listings={featuredListings} />
      <NewListings listings={trendingListings} />
      <WantedListings listings={wantedListings} />
      <BlogItems />
      {!isMobile && (
        <AdHorizontal>
          <AdBanner slotId="9529706829" />
        </AdHorizontal>
      )}
    </>
  );
};
export const getStaticProps: GetStaticProps = async () => {
  const [
    categoriesResponse,
    featuredListingResponse,
    trendingListingsResponse,
    wantedListingsResponse,
  ] = await Promise.all([
    client.get(`${config.apiUrl}/trending-categories`),
    client.get(`${config.apiUrl}/listings/featured`),
    client.get(`${config.apiUrl}/listings`),
    client.get(`${config.apiUrl}/listings_wanted`),
  ]);
  return {
    props: {
      canonicalUrl: 'https://www.rentable.pk',
      trendingCategories: categoriesResponse.data.categories.data,
      featuredListings: featuredListingResponse.data.listings,
      trendingListings: trendingListingsResponse.data.listings,
      wantedListings: wantedListingsResponse.data.listings,
      revalidate: 60,
    },
  };
};

export default LandingPage;
