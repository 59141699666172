import React, { useCallback } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { TextField, useMediaQuery } from '@material-ui/core';
import Router from 'next/router';
import { BREAKPOINTS } from 'src/utils/constants';
// import LocationSelect from 'src/LocationSelect';
import Autocomplete from 'src/components/Common/AutoComplete';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import useGetAllCategories from '../../../../hooks/api/categories/useGetAllCategories';

type TStyledProps = {
  isTablet?: boolean;
  isSmallScreen?: boolean;
};
const SearchSection = styled.div<TStyledProps>`
  background: white;
  padding: 40px;
  width: 340px;
  margin-top: ${({ isSmallScreen }) => (isSmallScreen && '20px') || 'auto'} !important;
`;
const SearchSectionTitle = styled.h5`
  font-size: 18px;
  margin: 15px 0;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1 1 auto;
`;
const StyledButton = styled(Button)<TStyledProps>`
  margin-top: ${({ isSmallScreen }) => (isSmallScreen && '20px') || 'auto'} !important;
  height: ${({ isSmallScreen }) => (isSmallScreen && '50px') || '40px'};
`;
const StyledInput = styled(TextField)`
  margin-top: 15px !important;
  input {
    //padding: 10px 0;
  }
`;
/* const StyledLocationSelect = styled(LocationSelect)`
  //width: 100%;
  .MuiInputBase-root {
    //width: 100%;
  }
`; */
const Search = () => {
  const isDesktop = useMediaQuery(BREAKPOINTS.DESKTOP);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [parentCat, setParentCat] = React.useState('');
  const { Categories } = useGetAllCategories();

  const handleTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectLocation = useCallback(
    (val) => {
      setLocation(val);
    },
    [setLocation]
  );
  const handleParentSelect = (event: any) => {
    const val = event.target.value;
    setParentCat(val);
  };

  const handleSubmit = useCallback(() => {
    const query = [];
    if (searchTerm.length) {
      query.push(`to_search=${searchTerm}`);
    }
    if (location.length) {
      query.push(`location=${location}`);
    }
    if (parentCat.length) {
      query.push(`parent_cat=${parentCat}`);
    }
    Router.push(`/search${(query.length && '?') || ''}${query.join('&')}`);
  }, [searchTerm, location, parentCat]);
  return (
    <SearchSection isSmallScreen={isDesktop}>
      <SearchSectionTitle> I&apos;m interested in...</SearchSectionTitle>
      <Form>
        <FormControl variant="outlined">
          <InputLabel id="ParentCategory">Category</InputLabel>
          <Select
            labelId="ParentCategory"
            id="ParentCategory"
            value={parentCat}
            onChange={handleParentSelect}
            label="Category"
          >
            <MenuItem value="" disabled>
              <em>Select Category</em>
            </MenuItem>
            {Categories &&
              Categories.map((category, index) => {
                return (
                  <MenuItem key={index} value={category.slug}>
                    {category.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <StyledInput
          value={searchTerm}
          placeholder="Search for..."
          onChange={handleTermChange}
          variant="outlined"
        />
        {/* <StyledLocationSelect handleSelect={handleSelectLocation} className="" /> */}

        <Autocomplete
          onChangeHandler={handleSelectLocation}
          style={{ margin: `15px 0` }}
          label="Enter Location"
        />
        <StyledButton
          isSmallScreen={isDesktop}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Search
        </StyledButton>
      </Form>
    </SearchSection>
  );
};

export default Search;
