import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

const StyledSlider = styled(Slider)`
  flex-basis: 30%;
  .slick-dots {
    text-align: left;
  }
  .slick-active {
    span {
      opacity: 1;
    }
  }
`;
const SlideItem = styled.div`
  outline: none;
`;
const SlideHeading = styled.h2`
  color: white;
  font-size: 32px;
`;
const SlideDetail = styled.p`
  color: white;
  font-size: 16px;
`;
const DotItemContainer = styled.div``;
const DotItem = styled.span`
  width: 20px;
  height: 3px;
  background: #fff;
  margin-right: 10px;
  display: inline-block;
  opacity: 0.5;
`;
const settings = {
  dots: true,
  autoplay: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 10000,
  dotsClass: 'slick-dots slick-thumb',
  customPaging: () => (
    <DotItemContainer>
      <DotItem />
    </DotItemContainer>
  ),
};
const TextSlider = () => {
  return (
    <StyledSlider {...settings}>
      <SlideItem>
        <SlideHeading>Check out ads submitted by our members and join us today</SlideHeading>
        <SlideDetail>
          Browse from more than <b>15,000,000</b> adverts while new ones come on daily bassis
        </SlideDetail>
      </SlideItem>
      <SlideItem>
        <SlideHeading>Make your ads stand more out by purchasing promotions</SlideHeading>
        <SlideDetail>You can choose among multiple promotions and also combine them</SlideDetail>
      </SlideItem>
      <SlideItem>
        <SlideHeading>Phone verification &amp; reviews will protect your purchase</SlideHeading>
        <SlideDetail>Buyers have no fear since we have conducted all safety measures</SlideDetail>
      </SlideItem>
    </StyledSlider>
  );
};

export default TextSlider;
