import React from 'react';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';

import BlogCard from 'src/components/BlogCard';
import Slider from 'react-slick';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useBlog from 'src/hooks/api/useBlog';
import { useMediaQuery } from '@material-ui/core';
import { BREAKPOINTS } from 'src/utils/constants';
import ListingCardSkeleton from 'src/components/ListingCard/AdCardSkeleton';
import Link from 'next/link';
import MuiLink from '@material-ui/core/Link';

const Section = styled('section')`
  background: ${({ theme }) => theme.colors.light.primary};
  padding: 40px 0;
  @media (max-width: 450px) {
    padding: 10px 0;
  }
`;
const SectionContainer = styled(Container)`
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
`;
const SectionHeadingContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const SectionHeading = styled('h1')`
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;
const SectionContent = styled('div')`
  display: flex;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  width: 100%;
  .slick-next {
    right: -15px;
    &:hover {
      bottom: 3px !important;
      box-shadow: rgba(46, 62, 72, 0.12) 0px 2px 8px 0px, rgba(46, 62, 72, 0.12) 0px 2px 4px 0px !important;
    }
  }
  .slick-prev {
    left: -15px;
  }
  .slick-disabled {
    display: none !important;
    background: gray !important;
    color: white !important;
    &:hover {
      background: gray !important;
      color: white !important;
    }
  }
`;
const NextArrow = styled(ArrowForwardIosIcon)`
  position: relative;
  font-size: 13px !important;
  left: 0px;
`;
const PrevArrow = styled(ArrowBackIosIcon)`
  position: relative;
  font-size: 13px !important;
  left: 2px;
`;
const ArrowContainer = styled.div`
  box-shadow: rgba(46, 62, 72, 0.12) 0px 0px 2px 0px, rgba(46, 62, 72, 0.12) 0px 2px 4px 0px;
  color: ${({ theme }) => theme.colors.primary} !important;
  border: 1px solid rgba(46, 62, 72, 0.12);
  bottom: 0px;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  position: absolute;
  top: 0px;
  width: 40px;
  z-index: 10;
  background: white !important;
  border-radius: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  &:before {
    display: none;
  }
`;

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledListingCardSkeleton = styled(ListingCardSkeleton)<any>`
  margin: 0 5px;
`;

const BlogItems: React.FC = () => {
  const isMobile = useMediaQuery(BREAKPOINTS.MOBILE);
  const { blogs } = useBlog();

  const SliderSettings = {
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 200,
    centerPadding: '10px',
    className: 'center',
    centerMode: isMobile,
    nextArrow: (
      <ArrowContainer>
        <NextArrow />
      </ArrowContainer>
    ),
    prevArrow: (
      <ArrowContainer>
        <PrevArrow />
      </ArrowContainer>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <Section>
      <SectionContainer maxWidth="lg">
        <SectionHeadingContainer>
          <SectionHeading>Latest blogs</SectionHeading>
          <Link href="/blogs" prefetch={false} passHref>
            <MuiLink href="/blogs" color="primary">
              See all
            </MuiLink>
          </Link>
        </SectionHeadingContainer>
        <SectionContent>
          <div style={{ width: '100%' }}>
            {/* <BlogCard /> */}
            {(blogs && (
              <Slider {...SliderSettings}>
                {blogs.map((item: any, indx: number) => {
                  return <BlogCard key={indx} blogData={item} />;
                })}
              </Slider>
            )) || (
              <SkeletonContainer>
                <StyledListingCardSkeleton />
                <StyledListingCardSkeleton />
                <StyledListingCardSkeleton />
                <StyledListingCardSkeleton />
              </SkeletonContainer>
            )}
          </div>
        </SectionContent>
      </SectionContainer>
    </Section>
  );
};

export default BlogItems;
